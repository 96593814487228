import './App.css';
import React, { Component } from 'react';

import HomePage from './components/home'


export default class App extends Component {
  render() {
    return <HomePage/>
  }
}